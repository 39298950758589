   #talkjs-container .UserMessage__message {
       border-radius: 24px !important;
       background: #ffb9a3 !important;
   }

   .ads_desc{
    position: absolute;
    width: 100%;
    bottom: 0px;
    right: 0px;
   }
  .advertise-button{
    border-radius: 20px;
  }
   .ads_desc:hover{
    background-color: rgb(255, 255, 255);
    transition-property: background-color;
    transition-duration: 0.4s;
    transition-timing-function: linear;
    transition-delay: 0.1s;
 }
 #blinking-text {
    animation: blink 1s linear infinite;
  }
  @keyframes blink {
    0%, 100% {opacity: 1;}
    50% {opacity: 0.4;}
  }

  #talkjs-container .talkjs-thread .talkjs-message .talkjs-message-content {
    font-family: 'nunito', sans-serif;
}

/* Change font style for user names */
#talkjs-container .talkjs-thread .talkjs-message .talkjs-sender-name {
    font-family: 'nunito', sans-serif;
}

/* Change font style for message timestamps */
#talkjs-container .ChatHeader__inline-block.ChatHeader__user.ChatHeader__ span{
    font-family: 'nunito', sans-serif;  
}
.ChatHeader__title{
  font-family: 'nunito', sans-serif !important;
  font-size: 16px;
}