.autocomplete-dropdown-container {
  position: absolute;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

.suggestion-item--active,
.suggestion-item:hover {
  background-color: #f8f9fa !important;
}

.suggestion-item {
  cursor: pointer;
  padding: 8px 12px;
  border-bottom: 1px solid #eee;
}

.quill-container {
  display: flex;
  flex-direction: column;
  min-height: 300px;
}

.quill-container .quill {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.quill-container .ql-container {
  flex: 1;
  overflow: auto;
  font-size: 16px;
  border: 1px solid #dee2e6;
  border-top: 0;
  border-radius: 0 0 4px 4px;
}

.quill-container .ql-toolbar {
  border: 1px solid #dee2e6;
  border-radius: 4px 4px 0 0;
  background-color: #fff;
}

.quill-container .ql-editor {
  min-height: 200px;
  max-height: none;
  overflow-y: auto;
}

.ql-editor p {
  margin-bottom: 0.5em;
}

/* Remove focus outline */
.ql-container:focus-within {
  outline: none;
}

/* Add styles for the form card */
.job-post-form-card {
  border: 2px solid #e8f3ff !important;
  border-radius: 12px;
  overflow: hidden;
}

.job-post-form-card .card-body {
  border-radius: 10px;
}

/* Update section styles */
.job-post-form-card .bg-light {
  background-color: #f8faff !important;
  border: 1px solid #e8f3ff !important;
}

.employment-type-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

@media (max-width: 768px) {
  .employment-type-wrapper {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 0.5rem;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .employment-type-wrapper::-webkit-scrollbar {
    display: none;
  }
}

.description-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  line-height: 1.5;
}
