.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99;
  background: rgb(0, 0, 0, 0.5);
}

.modal-container {
  align-items: centers;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 273px;
  z-index: 999;
  overflow: hidden;
  border-radius: 5px;
  padding: 10px;
  animation: fadeIn 1s ease both;
  background-color: white;
  border-radius: 20px;
  box-shadow: 5px 5px 20px rgba(#cbcdd3, 10%);
}
.error-msg {
  padding: 1rem;
}
.error-msg {
  font-size: 1.25rem;
}
.modal-close {
  display: flex;
  justify-content: end;
}

.header {
  font-size: 15px;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
}

.icon-box {
  color: #fff;
  /* position: absolute; */
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -70px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  z-index: 9;
  background: white;
  padding: 15px;
  text-align: center;
  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.1);
}
.fa-close {
  font-size: 33px;
  color: red;
}
.fa-check {
  font-size: 33px;
  color: green;
}
.closeBtn {
  width: 100%;
  border-radius: 20pc;
  background: white;
  color: #ef8d9c;
  box-shadow: 2px 2px 10px rgba(119, 119, 119, 0.5);
  transition: all 0.5s ease-in-out;
}
.closeBtn :hover {
  background: darken(#fcfcfc, 5%);
  transform: scale(1.05);
  transition: all 0.3s ease-in-out;
}
.message_p {
  font-size: 0.7em;
  font-weight: 600;
  color: #000000;
  letter-spacing: 1px;
}
