@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

* {
  font-family: 'Nunito', sans-serif;
}

:root {
  --sblue: rgba(13, 110, 253, 1);
  --primary-color: #185ee0;
  --secondary-color: #e6eef9;
}

.profile_card_body {
  box-shadow: 1px -2px 6px 1px lightgray;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.recommender_count {
  border-radius: 50%;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.recommendedDiv {
  float: right;
  margin: -80px 0px 0px 0px;
}

.status {
  font-weight: 800;
  padding: 10px 10px 10px 10px;
  margin: 0px 0px 17px 4px;
  font-size: 16px !important;
  box-shadow: 1px 1px 1px 1px;
}

.basic_status {
  background: rgb(201 242 240);
  color: #515151 !important;
  border: 1px solid rgb(193 255 238);
  font-size: 16px !important;
}

.gold_status {
  background: linear-gradient(90deg,
      rgb(255 243 81) 0%,
      rgb(226, 199, 77) 100%);
  color: black !important;
  border: 1px solid yellow;
  font-size: 16px !important;
  height: 27px;
}

.platinum_status {
  background: linear-gradient(90deg, #e3e3e3 0%, #8a8987 100%);
  border: 1px solid rgb(199 199 199);
  color: black;
  font-size: 16px !important;
  /* font-family: 'Playfair Display',serif; */
}

.col_1 {
  background: #1155cc;
  color: white;
  border-radius: 25px;
  padding: 32px 11px 6px 15px;
}

.col_2 {
  border: 1px solid;
  border-radius: 25px;
  padding: 15px 14px 0px 8px;
}

.col_3 {
  border-radius: 25px;
  padding: 30px 14px 25px 8px;
  background-color: #f1c232;
}

.col_12 {
  padding: 0px 0px 0px 12px;
}

.changeRecommenderBlock {
  background: #f4fbf5;
  border-radius: 25px;
  border: 1px solid #dfdfdf;
  padding: 16px;
}

.container {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
}

ul.ks-cboxtags {
  list-style: none;
}

ul.ks-cboxtags li {
  display: inline;
  margin: 0px 4px 0px 0px;
}

ul.ks-cboxtags li label {
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(139, 139, 139, 0.3);
  color: #adadad;
  border-radius: 7px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
  padding: 3px 7px;
  cursor: pointer;
  font-size: 16px;
  border: 2px solid #9fc2ff;
}

ul.ks-cboxtags li label::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
  font-size: 12px;
  padding: 2px 6px 2px 2px;
  /* content: '\f067'; */
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type='checkbox']:checked+label::before {
  /* content: '\f00c';  */
  transform: rotate(-360deg);
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type='checkbox']:checked+label {
  border: 2px solid #1155cc;
  color: #1155cc;
  transition: all 0.2s;
}

ul.ks-cboxtags li input[type='checkbox'] {
  display: absolute;
}

ul.ks-cboxtags li input[type='checkbox'] {
  position: absolute;
  opacity: 0;
}

ul.ks-cboxtags li input[type='checkbox']:focus+label {
  border: 2px solid #1155cc;
}

.side_bar {
  width: 100%;
  /* border: 1px solid #dfdfdf; */
  padding: 10px 10px 1px 10px;
  border-radius: 4px;
}

.side_btn {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  font-weight: 800;
  border: #1155cc 1px solid;
  color: #1155cc;
}

.side_btn:hover {
  background-color: #1155cc !important;
  color: white;
  cursor: pointer;
  transition-delay: 0.5ms;
}

.profile_picture {
  width: 200px;
  height: 200px;
  border-radius: 14px;
}

.change_picture_section {
  position: absolute;
  bottom: 0;
  right: 0;
}

.label_text {
  font-size: 18px;
  font-family: 'Nunito', sans-serif;
  font-weight: 400;
}



.profile_card_body {
  box-shadow: 2px 2px 2px 1px lightgray;
}

.avatar_image {
  object-fit: cover;
  box-shadow: 1px 1px 3px 2px #b9b9b9;
  height: 130px !important;
  width: 130px !important;
}

.pending_status {
  background: orange;
  font-size: 16px !important;
}

.react-date-picker__wrapper {
  border: none !important;
}

.img__wrap {
  position: relative;
  width: 200px;
  height: 200px;
}

.img__img {
  border-radius: 10px;
  /* width: 243px;
  height: 257px; */
  border: 1px dotted;
  margin: 48px 10px 10px 48px;
  /* margin: 48px 10px 10px -268px */
}

.img__description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(122, 122, 122, 0.72);
  color: #fff;
  visibility: hidden;
  opacity: 0;
  height: 200px;
  width: 200px;
  border-radius: 10px;

  /* transition effect. not necessary */
  transition:
    opacity 0.2s,
    visibility 0.2s;
}

.img__description .upload_btn {
  background: none;
  text-align: center;
  margin: auto;
  width: 50%;
  font-family: 'Nunito', sans-serif;
  font-weight: 800;
  cursor: pointer;
  opacity: 0;
  width: 200px;
  height: 200px;
  z-index: 1;
  position: relative;
}

.profile_Label {
  visibility: hidden;
  text-align: center;
  margin: -106px 0px 0px -60px;
  font-family: 'Nunito', sans-serif;
  font-weight: 800;
  cursor: pointer;
  position: absolute;
  z-index: 0;
  opacity: 0;
}

.img__wrap:hover .profile_Label {
  visibility: visible;
  opacity: 1;
}

.ant-tooltip {
  display: none;
}

.ant-tooltip-content {
  display: none;
}

.ant-upload-list-item {
  width: 100% !important;
  height: 257px !important;
  margin-left: 118px;
  padding: 2px;
}

.ant-upload-list {
  justify-content: center !important;
}

.ant-upload-list-item-image {
  object-fit: cover !important;
}

.ant-upload-list-item-container {
  width: 100% !important;
  height: 257px !important;
}

.ant-radio-group {
  margin: 25pc 0pc 0pc -33pc;
}

.ant-upload-list-item-image {
  object-fit: cover;
}

.sideMenu_a {
  box-shadow:
    0 1px 6px rgba(0, 0, 0, 0.12),
    0 1px 4px rgba(0, 0, 0, 0.24) !important;
}

.sideMenu_a .active {
  background-color: #1155cc;
  color: white;
}

.upload_caption {
  background: aliceblue;
  padding: 4px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: black !important;
  font-family: 'Nunito', sans-serif;
  justify-content: center;
}

.ant-upload.ant-upload-select {
  width: auto !important;
  height: auto !important;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.GMap_gmapContainer__02BZ8 {
  display: none;
}

.inner_row {
  border: 1px solid lightgrey;
  padding: 0px;
  border-radius: 6px;
  box-shadow: 2px 3px 3px 1px lightgray;
  font-family: 'Nunito', sans-serif;
  background: white;
}

.title_bar {
  /* background: rgba(13, 110, 253, 1); */
  padding: 14px;
  /* color: white !important; */
  font-size: 1.25rem;
}

.form_Input {
  font-size: 18px;
  font-family: 'Nunito', sans-serif;
  border: 1px solid #1155cc;
}

.side_menu {
  background: white;
}

.fieldset_ {
  margin: 10px 10px 10px 1px;
  padding: 10px 10px 10px 10px;
  border-radius: 10px;
  border-width: 1px;
  border: 1px solid #adadad;
  box-shadow: 1px 1px 4px 1px #ac9fbbe0;
}

.legend_ {
  position: absolute;
  top: -3px;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
  background-color: #f4fbf5;
  padding: 0px 6px 0px 6px;
  font-size: 18px;
  left: 14%;
  transform: translate(-50%, 0);
}

.location-search-input {
  border: 1px solid #e9ecef;
  font-size: 16px;
  line-height: 26px;
  border-radius: 6px;
  color: #3c4858 !important;
  text-align: left;
  width: 100%;
  display: block;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  background-color: #ffffff;
  background-clip: padding-box;
  appearance: none;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  font-family: 'Nunito', sans-serif;
}

.autocomplete-dropdown-container {
  position: absolute;
  z-index: 9;
  width: 100%;
  padding: 0px;
}

.suggestion-item,
.suggestion-item--active {
  border: 1px solid lightgray;
  padding: 5px;
}

.form-icon .icons {
  position: absolute;
  top: 13px;
  left: 18px;
  color: black;
}

.react-date-picker__clear-button {
  display: none;
}

.fa {
  position: absolute;
  top: 13px;
  left: 18px;
  /* font-size: 18px; */
}

.ant-upload-list-item-action {
  display: none;
}

.select__value-container {
  max-height: 38px;
  overflow: auto !important;
}

.legendIcon {
  width: 30px;
}

.legendName {
  font-size: 1.375rem;
  font-weight: 600;
}

.radio-section {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

h1 {
  margin-bottom: 20px;
}

.radio-item [type='radio'] {
  display: none;
}

.radio-item {
  margin: 0px 20px 0px 0px;
}

.radio-item label {
  display: block;
  padding: 4.5px 35px;
  background: white;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  min-width: 96%;
  white-space: nowrap;
  position: relative;
  transition: 0.4s ease-in-out 0s;
  color: #1155cc;
  border: 2px solid #d1d1d1;
  margin: 2px;
  padding-right: 6px !important;
}

.radio-item label:after,
.radio-item label:before {
  content: '';
  position: absolute;
  border-radius: 50%;
}

.radio-item label:after {
  height: 19px;
  width: 19px;
  border: 2px solid #524eee;
  left: 10px;
  top: calc(50% - 9px);
}

.radio-item label:before {
  background: #524eee;
  height: 20px;
  width: 20px;
  left: 10px;
  top: calc(50% - 9px);
  transform: scale(5);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease-in-out 0s;
}

.registration .radio-item .label0:after {
  height: 19px;
  width: 19px;
  border: 2px solid orange;
  left: 10px;
  top: calc(50% - 9px);
}

.registration .radio-item .label0:before {
  background: orange;
  height: 20px;
  width: 20px;
  left: 10px;
  top: calc(50% - 9px);
  transform: scale(5);
  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease-in-out 0s;
}

.registration .radio-item:nth-child(1) [type='radio']:checked~label {
  border-color: orange;
}

.radio-item [type='radio']:checked~label {
  border-color: #524eee;
}

.radio-item [type='radio']:checked~label::before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.radio-list {
  display: flex;
  flex-wrap: wrap;
}

.carousel-indicators {
  width: 10px;
  height: 10px !important;
}

@media only screen and (max-width: 640px) {
  .sqs-block-html {
    margin-top: 35px;
  }

  .legendName {
    font-size: 1rem;
  }

  .radio-list {
    display: block;
  }

  .radio-item label {
    padding: 6px 28px;
    width: 166px;
  }

  .radio-item {
    width: 77%;
  }

  .radio-item label:after {
    height: 17px;
    width: 17px;
    left: 6px;
  }

  .radio-item label:before {
    height: 17px;
    width: 17px;
    left: 6px;
  }

  .title-text {
    margin: auto;
    text-align: center;
    display: table;
  }

  .flex-container {
    display: block;
  }

  /* .col_whole_card {
    margin-top: 50px;
  } */

  .memberstatus {
    display: table;
  }
}

@media only screen and (max-width: 640px) {
  .register-radio label {
    padding: 6px 32px;
    width: 200px;
  }

  .register-radio .label0:before {
    height: 17px !important;
    width: 17px !important;
  }

  .register-radio .label0:after {
    height: 17px !important;
    width: 17px !important;
  }
}

.form-check-inline {
  margin-right: 0rem;
}

.react-date-picker__inputGroup {
  font-size: 18px;
}

.radio-item .labelcolor0 {
  color: black;
}

.radio-item .labelcolor0:before {
  background: black;
  border-color: black;
}

.radio-item .labelcolor0:after {
  border-color: black;
}

.radio-item .labelcolor1:after {
  border-color: rgb(255, 191, 0);
}

.radio-item .labelcolor1:before {
  background: rgb(255, 191, 0);
  border-color: rgb(255, 191, 0);
}

.radio-item .labelcolor2:after {
  border-color: rgb(0, 0, 255);
}

.radio-item .labelcolor2:before {
  background: rgb(0, 0, 255);
  border-color: rgb(0, 0, 255);
}

.radio-item .labelcolor3:after {
  border-color: rgb(150, 75, 0);
}

.radio-item .labelcolor3:before {
  background: rgb(150, 75, 0);
  border-color: rgb(150, 75, 0);
}

.radio-item .labelcolor4:after {
  border-color: rgb(128, 128, 128);
}

.radio-item .labelcolor4:before {
  background: rgb(128, 128, 128);
  border-color: rgb(128, 128, 128);
}

.radio-item .labelcolor5:after {
  border-color: rgb(0, 128, 0);
}

.radio-item .labelcolor5:before {
  background: rgb(0, 128, 0);
  border-color: rgb(0, 128, 0);
}

.radio-item .labelcolor6:after {
  border-color: rgb(200, 181, 117);
}

.radio-item .labelcolor6:before {
  background: rgb(200, 181, 117);
  border-color: rgb(200, 181, 117);
}

.radio-item .labelcolor7:after {
  border-color: rgb(255, 0, 0);
}

.radio-item .labelcolor7:before {
  background: rgb(255, 0, 0);
  border-color: rgb(255, 0, 0);
}

.radio-item [type='radio']:checked~.labelcolor0 {
  border-color: black;
}

.radio-item [type='radio']:checked~.labelcolor1 {
  border-color: rgb(255, 191, 0);
}

.radio-item [type='radio']:checked~.labelcolor2 {
  border-color: rgb(0, 0, 255);
}

.radio-item [type='radio']:checked~.labelcolor3 {
  border-color: rgb(150, 75, 0);
}

.radio-item [type='radio']:checked~.labelcolor4 {
  border-color: rgb(128, 128, 128);
}

.radio-item [type='radio']:checked~.labelcolor5 {
  border-color: rgb(0, 128, 0);
}

.radio-item [type='radio']:checked~.labelcolor6 {
  border-color: rgb(200, 181, 117);
}

.radio-item [type='radio']:checked~.labelcolor7 {
  border-color: rgb(255, 0, 0);
}

.professionIcon {
  /* width: 125px; */
  height: 55px;
  margin: auto;
}

.profession_li {
  padding: 5px 10px 5px 10px;
  background: white;
  border: 2px solid #a0acff;
  border-radius: 9px;
  width: 135px;
  margin: 0px 0px 0px 5px;
  cursor: pointer;
  margin: auto;
}

.profession_ul {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  margin-bottom: 0px;
}

.profession_ul .active {
  border: 2px solid #1155cc !important;
  background-color: white;
}

.profession_ul .active_text {
  border-bottom: 2px solid;
}

.filter {
  padding-top: 27px;
  font-size: 15px;
  font-weight: 600;
  color: #8e8e8e;
}

.status_fav {
  display: flex;
  justify-content: space-between;
}

.marital_status {
  background: linear-gradient(45deg, #3ea43c, #6bb866);
  border-radius: 5px;
  padding: 3px;
  color: white;
  margin: 8px;
}

.heart {
  width: 30px;
  cursor: pointer;
  margin: 6px 4px 0px 1px;
}

.initiateD_btn {
  font-size: 15px !important;
  border-radius: 5px !important;
  background-color: #0d6efd !important;
  border: 2px solid #0d6efd !important;
  font-weight: 600 !important;
  box-shadow: 1px 1px 5px 1px darkgrey !important;
  color: white !important;
  width: 100%;
}

.initiateD_btn:hover {
  background-color: #0d6efd !important;
  border: 2px solid #0d6efd !important;
  color: white !important;
}

.connection_btn {
  border-radius: 50x !important;
  font-size: 15px !important;
  border: 2px solid #3eed14 !important;
  font-weight: 600 !important;
  background: none !important;
  color: #00a400 !important;
  width: 100%;
}

.connection_btn:hover {
  background-color: #00a400 !important;
  border: 2px solid !important;
  color: white !important;
}

.filter_title,
.custum-filter {
  color: #1155cc;
  /* font-size: 13px; */
  font-weight: 300;
}

.mdi-heart::before {
  content: '\F02D1';
}

.designation {
  font-family: 'Nunito', sans-serif;
}

.imageSlider img {
  height: 100%;
  /* object-fit: contain; */
}

.img_thumb {
  width: 300px;
  height: 300px;
  /* border-radius: 20px;  */
  /* width: 235px; */
  /* object-fit: cover; */
  /* border-top-right-radius: 10px; */
}

.slick-arrow {
  color: black !important;
  width: 74px !important;
  z-index: 99;
  height: 32px !important;
  padding: 0px !important;
  /* margin-top: 11px; */
}

.slick-next:before {
  font-size: 25px !important;
  line-height: 1 !important;
  opacity: 1 !important;
  color: rgb(165, 165, 165) !important;
}

.slick-prev:before {
  font-size: 25px !important;
  line-height: 1 !important;
  opacity: 1 !important;
  color: rgb(165, 165, 165) !important;
}

.imageSlider .slick-arrow {
  opacity: 0;
}

.imageSlider:hover .slick-arrow {
  opacity: 1;
}

.imageSlider .slick-dots {
  position: absolute;
  bottom: 10px;
  /* Adjust as needed */
  width: 100%;
  /* Adjust width as needed */
  display: flex;
  justify-content: center;
}

.imageSlider.slick-dots li {
  margin: 0 5px;
  /* Adjust spacing as needed */
}

.imageSlider .slick-dots li button {
  font-size: 12px;
  /* Adjust size as needed */
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.imageSlider.slick-dots li button:before {
  font-family: 'slick';
  font-size: 12px;
  /* Adjust size as needed */
  line-height: 20px;
  opacity: 0.75;
  /* Adjust opacity as needed */
  color: white;
  /* Adjust color as needed */
}

.imageSlider .slick-dots li.slick-active button:before {
  opacity: 1;
  color: white;
  /* Adjust color as needed */
}

.filter_slider .slick-dots li button {
  display: none !important;
}

.like:hover {
  -webkit-text-stroke: red;
  -webkit-text-fill-color: red;
}

.like:focus {
  -webkit-text-stroke: red;
  -webkit-text-fill-color: red;
}

.verticalLine {
  border-right: 1px solid rgb(122, 122, 122);
  padding-left: 13px;
}

.modalIcon {
  width: 21px;
}

.carousel {
  width: 330px;
}

.carousel-inner {
  width: 75%;
}

.carousel-item img {
  height: 250px;
  width: 100% !important;
  border-radius: 50%;
}

/* .section_user_home_page {
  padding: 50px 0 !important;

} */

.mdi-heart {
  text-shadow: 1px 1px 4px blue;
  color: ghostwhite;
  font-size: 1.4rem;
}

.show_more_btn {
  text-decoration: underline !important;
  font-weight: 600;
}

.form_check_box {
  margin-right: 0rem;
  padding-left: 12px;
}

.form_check_box .form-check-label {
  font-size: 14px;
}

.form_check_box li {
  display: block !important;
}

.profileBtn:hover {
  color: white !important;
}

/* .profile_data_span {
  margin: 0px 10px 0px 12px;
} */

.menu_slider .profession_li {
  width: 155px;
}

.redheart {
  -webkit-text-stroke: red;
  -webkit-text-fill-color: red;
}

.configuration_btn .active {
  background-color: #2f55d4;
}

.favourite_avatar_img {
  border-radius: 50%;
  height: 60px !important;
  width: 60px !important;
}

.shareBtn {
  position: absolute !important;
  top: 14px;
  right: 51px;
  opacity: 0.5;
  height: 39px;
  color: blue !important;
  border: 1px solid blue !important;
}

.shareUrlbtn {
  position: absolute;
  right: 60px;
}

.shareBtn:hover {
  background-color: blue !important;
  color: white !important;
}

.copy_Modal_body {
  background: #383838;
  color: white;
}

.connectingBtn {
  font-weight: 600 !important;
  border: 1px solid red !important;
  color: red !important;
  /* width: 72px; */
  /* height: 45px; */
  border-radius: 7px;
}

.cursor-pointer {
  cursor: pointer;
}

.rc-slider-track {
  background-color: #1155cc;
}

.rc-slider-handle {
  border: solid 2px #1155cc;
}

.font_size18 {
  font-size: 18px;
}

.font_size16 {
  font-size: 16px;
}

.font_size14 {
  font-size: 14px;
}

.font_size15 {
  font-size: 15px;
}

.font_weight300 {
  font-weight: 300;
}

.width100 {
  width: 100%;
}

.width25 {
  width: 25px;
}

.color_2698EE {
  color: #2698ee;
}

.color_021631 {
  background-color: #021631 !important;
}

.title-text {
  display: inline-block;
  margin-right: 5px;
  /* Adjust as needed */
}

@media (max-width: 768px) {

  /* Adjust breakpoint as needed */
  .title-text.username {
    text-align: center;
    width: 100%;
  }

  .status-line {
    width: 100%;
    text-align: center;
  }

  .status-line>p {
    display: inline-block;
    margin: 0 5px;
    /* Adjust as needed */
  }
}

.subcription_required_text {
  border-radius: 10px;
  background-color: green;
}

.gm-style .gm-style-iw-c {
  max-width: 232px !important;
  max-height: 234px !important;
  overflow-y: auto;
}

.gm-style-iw-d {
  overflow: auto !important;
}

::-webkit-scrollbar-track {
  width: 5px !important;
  border-radius: 10px;
}

.ql-snow {
  background-color: white !important;
}

.width34 {
  width: 34px;
}

.markerlabel {
  margin-top: 50px;
  text-shadow: 2px 2px 5px white;
}

.slider_img {
  height: 142px !important;
  border-radius: 7px;
}

.memberheader_div {
  background: #005c85;
}

.memberImage_col img {
  height: 200px !important;
  border-radius: 6px;
  border: 3px solid #252525;
}

.memberImage_col .carousel {
  width: 85%;
}

.name_div {
  text-transform: uppercase;
}

.name_div .name {
  color: #7ff2ff;
}

.memberheader_label {
  color: #005c85;
  text-decoration: underline;
}

.membericon {
  color: #005c85;
}

.fa-handshake-o,
.fa-comments,
.fa-exchange,
.fa-angle-double-right {
  position: static;
}

.fa-facebook,
.fa-instagram,
.fa-twitter,
.fa-linkedin {
  position: static;
  font-size: 25px;
  padding: 5px;
  border-radius: 5px;
  width: 30px;
}

.fa-facebook:hover {
  cursor: pointer;
  background-color: #021631;
}

.fa-instagram:hover {
  cursor: pointer;
  background-color: #021631;
}

.fa-twitter:hover {
  cursor: pointer;
  background-color: #021631;
}

.fa-linkedin:hover {
  cursor: pointer;
  background-color: #021631;
}

.connectBtn {
  /* width: 72px; */
  /* height: 45px; */
  border-radius: 7px;
  background: green !important;
}

.viewBtn {
  /* width: 72px; */
  /* height: 45px; */
  border-radius: 7px;
}

.connectImg {
  width: 18px;
}

.totalCount {
  font-size: 17px;
}

.search {
  position: relative;
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}

.search input {
  height: 50px;
  text-indent: 25px;
  border: 2px solid #d6d4d4;
}

.search input:focus {
  box-shadow: none;
  border: 2px solid blue;
}

.search .fa-search {
  position: absolute;
  top: 16px;
  left: 16px;
}

.search button {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 40px;
  width: 110px;
  background: blue;
}

.wave {
  position: absolute;
  margin-top: -140px;
  width: 100%;
}

.members_Card {
  border-radius: 8px;
}

.radioimagesize2 {
  width: 200px;
}

.radioimagesize1 {
  width: 150px;
}

.radioimagesize0 {
  width: 100px;
}

.profession_text {
  font-size: 22px;
}

.username {
  font-size: 26px;
}

.profileIcon {
  width: 20px;
  height: 20px;
}

.profession_custum_li {
  width: 135px !important;
}

.personal_save_change {
  background-color: var(--sblue) !important;
  width: 12rem;
}

.form-border-radius {
  border-radius: 11px !important;
}

.allains-container,
.allains-container-button {
  background-color: rgba(33, 178, 82, 1);
  color: #ffffff;
  width: 12rem;
  border-radius: 5px;
}

@media (max-width: 766px) {
  .profile-card {
    text-align: center;
  }

  .button-basic {
    justify-content: center;
  }
}

@media (max-width: 340px) {
  .profession_custum_li {
    width: 96px !important;
  }
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.fontSize16 {
  font-size: 16px;
}

.subscription_table {
  border-collapse: collapse;
  border-radius: 15px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #d0d8dc;
}

.text_primary {
  color: #1c44fe !important;
}

.bg_primary {
  background-color: #1c44fe !important;
}

.border_top_right_rad {
  border-top-right-radius: 15px;
}

.pointer_event_none {
  pointer-events: none;
  cursor: not-allowed;
}

.heart_li {
  right: 0px;
  z-index: 999;
  padding: 10px;
}

.label_img,
.modal-img {
  padding: 4px;
  border-radius: 5px;
  width: 24px;
  height: 24px;
}

.profile_modal_font span,
p {
  font-family: 'Lato', sans-serif;
}

.modal_svg {
  background: #005c85;
  padding: 4px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
}

.value_text {
  margin-left: 40px;
}

.fixHeight {
  height: 204px;
}

.mt-20 {
  margin-top: 20px;
}

.fontSize18 {
  font-size: 18px;
}

.dissmissicon {
  cursor: pointer;
  padding: 13px;
}

.dissmissicon:hover {
  background: #d0d7ff;
  border-radius: 50%;
}

.popupImg {
  width: 70px;
}

.modal-img-bg-green {
  background: #66e38d;
}

.modal-img-bg-blue {
  background: #005c85;
}

.text_card_body {
  height: 154px;
}

.profilevisit_Count {
  font-size: 17px;
  margin: 0px 0px 0px 10px;
  color: green;
  position: absolute;
  background: bisque;
  padding: 1px 8px 0px 8px;
  cursor: pointer;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  /* margin: 50px; */
  padding: 0px 20px;
}

.pricing-table {
  box-shadow: 0px 0px 18px #ccc;
  text-align: center;
  padding: 30px 0px;
  border-radius: 5px;
  position: relative;
}

.pricing-table .head {
  border-bottom: 1px solid #eee;
  padding-bottom: 50px;
  transition: all 0.5s ease;
}

.pricing-table:hover .head {
  border-bottom: 1px solid #2e2de2;
}

.pricing-table .head .title {
  margin-bottom: 3px;
  font-size: 20px;
  font-weight: 500;
}

.pricing-table .content .price {
  background: linear-gradient(to right, #2e2de2 0%, #0d6efd 100%);
  width: 65px;
  height: 65px;
  margin: auto;
  line-height: 60px;
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0px 0px 10px #ccc;
  margin-top: -38px;
  transition: all 0.5s ease;
}

.pricing-table:hover .content .price {
  transform: scale(1.2);
}

.pricing-table .content .price h1 {
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  margin-top: 14px;
}

.pricing-table .content ul {
  list-style-type: none;
  margin-bottom: 20px;
  padding-top: 10px;
}

.pricing-table .content ul li {
  margin: 20px 0px;
  font-size: 14px;
  color: #555;
}

.pricing-table .content .sign-up {
  background: linear-gradient(to right, #2e2de2 0%, #0d6efd 100%);
  border-radius: 40px;
  font-weight: 500;
  position: relative;
  display: inline-block;
}

.pricing-table .btn {
  color: #fff;
  padding: 14px 40px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3 linear;
  transition: all 0.3 linear;
  border: none;
  font-size: 14px;
  text-transform: capitalize;
  position: relative;
  text-decoration: none;
  margin: 2px;
  z-index: 9999;
  text-decoration: none;
  border-radius: 50px;
}

.pricing-table .btn:hover {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
}

.pricing-table .btn.bordered {
  z-index: 50;
  color: #333;
}

.pricing-table:hover .btn.bordered {
  color: #fff !important;
}

.pricing-table .btn.bordered:after {
  background: #fff none repeat scroll 0 0;
  border-radius: 50px;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3 linear;
  transition: all 0.3 linear;
  width: 100%;
  z-index: -1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.pricing-table:hover .btn.bordered:after {
  opacity: 0;
  transform: scale(0);
}

@media screen and (max-width: 768px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
}

.payment_section {
  background: #efefef;
}

.darkgoldenrod {
  color: darkgoldenrod;
}

.celebration_gif {
  position: absolute;
  z-index: 9;
}

.tabs {
  display: flex;
  position: relative;
  box-shadow:
    0 0 1px 0 rgba(#185ee0, 0.15),
    0 6px 12px 0 rgba(#185ee0, 0.15);
  padding-top: 0.75rem;
  border-radius: 99px;

  * {
    z-index: 2;
  }
}

.tabs input[type='radio'] {
  display: none;
}

.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: color 0.15s ease-in;
  width: 49%;
  padding-top: 0px;
  border-radius: 12px;
  height: 41px;
  background: whitesmoke;
  border: 1px solid blue;
}

.tabs input[type='radio'] {
  &:checked {
    &+label {
      color: var(--primary-color);
      background: #3f3f3f;
      color: white;
      font-weight: 700;
      border-radius: 12px;
    }
  }
}

.tabs input[id='radio-1'] {
  &:checked {
    &~.glider {
      transform: translateX(0);
    }
  }
}

.tabs input[id='radio-2'] {
  &:checked {
    &~.glider {
      transform: translateX(100%);
    }
  }
}

.tabs .glider {
  position: absolute;
  display: flex;
  height: 40px;
  width: 180px;
  background-color: var(--secondary-color);
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}

.active-users {
  background: green;
  color: white;
  border-radius: 48px;
  padding: 0 7px;
  font-weight: bold;
}

.pending-users {
  background: orange;
  color: white;
  border-radius: 48px;
  padding: 0 7px;
  font-weight: bold;
}

.rejected-users {
  background: red;
  color: white;
  border-radius: 48px;
  padding: 0 7px;
  font-weight: bold;
}

.deactived-users {
  background: #972626;
  color: white;
  border-radius: 48px;
  padding: 0 7px;
  font-weight: bold;
}

@media (max-width: 700px) {
  .tabs {
    transform: scale(1);
  }
}

.subscribe_btn {
  border: 1px solid;
  border-radius: 5px;
  box-shadow: 1px 1px black;
  padding: 3px;
}

.border_rad20 {
  border-radius: 20px;
}

.search-box {
  width: fit-content;
  height: fit-content;
  position: relative;
}

.input-search {
  height: 50px;
  width: 50px;
  border-style: none;
  padding: 10px;
  font-size: 14px;
  letter-spacing: 2px;
  outline: none;
  border-radius: 25px;
  transition: all 0.5s ease-in-out;
  background-color: #22a6b3;
  padding-right: 40px;
  color: rgb(39, 39, 39);
}

.input-search::placeholder {
  color: rgba(82, 82, 82, 0.5);
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 100;
}

.btn-search {
  width: 50px;
  height: 50px;
  border-style: none;
  font-size: 18px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 0px;
  color: #ffffff;
  background-color: transparent;
  pointer-events: painted;
}

.btn-search:focus~.input-search {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgb(120 120 120 / 50%);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

.btn-search:focus {
  color: black;
}

.input-search:focus {
  width: 300px;
  border-radius: 0px;
  background-color: transparent;
  border-bottom: 1px solid rgb(120 120 120 / 50%);
  transition: all 500ms cubic-bezier(0, 0.11, 0.35, 2);
}

.prog-dismiss {
  cursor: pointer;
  padding: 8px;
  margin: -3px 3px;
}

.prog-dismiss:hover {
  background: #bbbdc4;
  border-radius: 50%;
}

.search-icons {
  position: absolute;
  right: 30px;
  top: 8px;
}

.half-screen-background {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.half-screen-background::before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  background: url('../../../assets/images/mainBG-image.jpg');
  /* background: linear-gradient(87deg, #11cdef 0, #1171ef 100%) !important; */
  background-size: cover;
  background-position: center;
}

.refresh-button {
  cursor: pointer;
  color: #252525;
}

.srch_frm_sction {
  align-items: center;
  display: flex;
}

.search_input {
  border: 2px solid #3150c5 !important;
  width: 30rem !important;
  border-radius: 17px !important;
}

.search_btn {
  border-radius: 17px !important;
  background-color: #3150c5 !important;
  color: white !important;
}

@media (max-width: 1000px) {
  .search_input {
    width: unset !important;
  }
}


.for-dropdown {
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 2;
  /* height: 50px; */
  transition: all 200ms linear;
  border-radius: 4px;
  /* width: 220px; */
  letter-spacing: 1px;
  align-items: center;
  justify-content: center;
  /* background-color: #ffeba7; */
  cursor: pointer;
  /* color: #102770; */
  box-shadow: 0 12px 35px 0 rgba(255, 235, 167, 0.15);
}

/* Dropdown content hidden by default */
.section-dropdown {
  position: absolute;
  /* padding: 5px; */
  background-color: #fff;
  top: 55px;
  left: 0;
  width: 100%;
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  transform: translateY(20px);
  /* transition: all 200ms linear; */
  z-index: 2;
  width: 220px;
  color: black;
}

/* Show dropdown when hovering over the container or dropdown */
.sec-center .section-dropdown {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.section-dropdown:after {
  position: absolute;
  top: -7px;
  left: 30px;
  width: 0;
  height: 0;
  /* border-left: 8px solid transparent;
  border-right: 8px solid transparent; */
  border-bottom: 8px solid #f8eeee;
  content: '';
  display: block;
  z-index: 2;
  transition: all 200ms linear;
}

.section-dropdown a {
  position: relative;
  color: #000;
  transition: all 200ms linear;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 15px;
  border-radius: 2px;
  padding: 8px 0;
  padding-left: 20px;
  padding-right: 15px;
  /* margin: 2px 0; */
  text-align: left;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: start;
}

.section-dropdown a:hover {
  color: #102770;
  /* background-color: #ffeba7; */
  background-color: #ffff;
}

.uil {
  font-size: 24px;
  margin-left: 1px;
  transition: transform 200ms linear;
}

/* Rotate arrow on hover of the container */
/* .sec-center:hover .uil {
  transform: rotate(180deg);
} */

.purplebtn {
  background-color: #0d6efd !important;
}

.lightgrn {
  /* background-color: #25d366 !important; */
  background-color: #05762f !important;
}

.darkblu {
  background-color: #04668f !important;
}

.blackbtn {
  background-color: #000 !important;
}

.purplebtn:hover {
  background-color: #ffff !important;
  border: 1px solid #0d6efd;
}

.blackbtn:hover {
  background-color: #ffff !important;
  /* border: 1px solid #000; */
  border: 1px solid #0d6efd;
}

.lightgrn:hover {
  background-color: #ffff !important;
  /* border: 1px solid #05762f; */
  border: 1px solid #0d6efd;
}

.darkblu:hover {
  background-color: #ffff !important;
  /* border: 1px solid #0d6efd; */
  border: 1px solid #0d6efd;
}

/* global events */
.evntstatusbadge {
  height: fit-content;
}

@media screen and (max-width: 991px) {

  .dark-light:checked+label,
  .dark-light:not(:checked)+label {
    top: 20px;
    right: 20px;
  }
}

@media (max-width: 990px) {
  .section-dropdown {
    position: relative;
    margin-top: 5px;
    top: 0;
  }
}

.style-font {
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

/* Container for the image and icon */
.slider-image-wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
  /* Ensures the icon stays within bounds */
}

/* Image styling */
.slider-image-wrapper img {
  display: block;
  /* Ensures images take full container size */
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Adjust image scaling */
  z-index: 0;
  /* Keep image behind the overlay and icon */
}

/* Transparent black overlay (hidden by default) */
.slider-image-wrapper .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  /* Transparent by default */
  transition: background-color 0.3s ease;
  z-index: 1;
  /* Overlay above the image */
  opacity: 0;
  /* Initially hidden */
}

/* Eye icon styling */
.slider-image-wrapper .fea.icon-eye {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* Center the icon */
  font-size: 2rem;
  /* Adjust icon size */
  color: white;
  /* Icon color */
  opacity: 0;
  /* Initially hidden */
  transition: opacity 0.3s ease;
  /* Smooth fade-in */
  z-index: 2;
  /* Icon above the overlay */
}

/* Hover effects: show overlay and eye icon */
.slider-image-wrapper:hover .overlay {
  background-color: rgba(0, 0, 0, 0.5);
  /* Transparent black overlay on hover */
  opacity: 1;
  /* Make the overlay visible */
}

.slider-image-wrapper:hover .fea.icon-eye {
  opacity: 1;
  /* Show the eye icon on hover */
}


.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1); /* Semi-transparent black */
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden; /* Initially hidden */
}

.image-modal.open {
  opacity: 1;
  visibility: visible;
}

.modal-content1 {
  background-color: white;
  padding: 20px;
  position: relative; /* For positioning close button */
  max-width: 90%; /* Limit the width of the modal */
  max-height: 90%; /* Prevent overflow */
  border-radius: 8px; /* Rounded corners */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.modal-image {
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 500px; /* Ensuring image doesn't get too large */
}

.close-button {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: black;
  color: white;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 0.3); /* Soft hover effect */
  color: black;
}

.close-button:focus {
  outline: none;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .close-button {
    width: 35px;
    height: 35px;
    font-size: 18px;
    top: 8px;
    right: 8px;
  }

  .modal-image {
    height: 400px;
  }

  .image-modal {
    width: 100%;
    height: 100%;
  }
}

/* Media query for very small mobile screens */
@media (max-width: 480px) {
  .close-button {
    width: 30px;
    height: 30px;
    font-size: 16px;
    top: 6px;
    right: 6px;
  }

  .modal-image {
    height: 300px; /* Smaller image on mobile */
  }
}



/* General styles for carousel images */
#carousel-image-1,
#carousel-image-2,
#carousel-image-3 {
  position: relative;
  overflow: hidden;
  /* Ensures overlay stays within image bounds */
  transition: transform 0.3s ease;
  /* Smooth zoom effect */
}

/* Zoom effect on hover */
#carousel-image-1:hover img,
#carousel-image-2:hover img,
#carousel-image-3:hover img {
  transform: scale(1.05);
}

/* Overlay pseudo-element */
#carousel-image-1::after,
#carousel-image-2::after,
#carousel-image-3::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black transparent overlay */
  opacity: 0;
  /* Initially invisible */
  transition: opacity 0.3s ease;
  /* Smooth fade-in effect */
  z-index: 1;
}

/* Show overlay on hover */
#carousel-image-1:hover::after,
#carousel-image-2:hover::after,
#carousel-image-3:hover::after {
  opacity: 1;
}

.image-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer;
}


.preview-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Black transparent overlay */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  /* Smooth fade-in effect */
}

.image-wrapper:hover .preview-layer {
  opacity: 1;
}

.preview-layer .fea.icon-eye {
  font-size: 2rem;
  color: white;
  /* Eye icon color */
}


.new-style {
  font-family: 'Nunito', sans-serif;
}

.new-icon {
  color: #0d6efd;
}



/* General media query for mobile screens including iOS */
@media (max-width: 767px) {
  .profile_down_arrow {
   display: none;
  }
}

/* For iPhone 5, SE (1st gen), and small devices (320px) */
@media (max-width: 320px) {
  .profile_down_arrow {
    display: none;
   }
}

/* For iPhone 6/7/8 and SE (2nd gen) (375px) */
@media (max-width: 375px) {
  .profile_down_arrow {
    display: none;
   }
}

/* For iPhone 6+/7+/8+ and larger iPhones (414px) */
@media (max-width: 414px) {
  .profile_down_arrow {
    display: none;
   }
}



@media (min-width: 1024px) {
  .section_user_home_page {
    margin-top: 4.5rem;
  }

 
}

/* General Container Styles */
.filter-slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  padding: 5px 0;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filter-slider-wrapper {
  width: 90%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  cursor: grab;
}

.filter-slider-wrapper:active {
  cursor: grabbing;
}

.filter-slider {
  display: flex;
  gap: 20px;
  align-items: center;
  transition: transform 0.3s ease-out;
}

/* Filter Items */
.filter-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  user-select: none;
}

.filter-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-item-active {
  transform: translateY(-10px);
  border-radius: 10px;
  background-color: #fbe9e7;
  box-shadow: 0 4px 12px rgba(56, 182, 255, 0.3);
}

.filter-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  margin-bottom: 8px;
}

.filter-icon-img {
  width: 30px;
  height: 30px;
}

.filter-text {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.filter-item-active .filter-text {
  color:#0d6efd;
}

/* Slider Buttons */
.slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ffffff;
  border: 2px solid #ddd;
  border-radius: 50%;
  font-size: 24px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.prev-btn {
  left: 10px;
}

.next-btn {
  right: 10px;
}

.slider-btn:hover {
  background-color:#0d6efd;
  color: #ffffff;
  box-shadow: 0 6px 10px rgba(56, 165, 255, 0.3);
}

/* Responsive Design */
@media (max-width: 768px) {
  .filter-slider {
    gap: 20px;
  }

  .filter-item {
    width: 80px;
  }

  .filter-icon {
    width: 50px;
    height: 50px;
  }

  .filter-icon-img {
    width: 24px;
    height: 24px;
  }

  .filter-text {
    font-size: 12px;
  }

  .slider-btn {
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}

.active-menu {
  color: #095ab1; /* Active color */
  font-weight: bold;
  border-bottom: 2px solid #007bff; /* Underline */
}



@media (max-width: 768px) {
  .spacing{
    margin-top: 1.0rem;
  }

  .chat-up-spacing{
    bottom: 100px;
  }
}

.side-border {
  border: 2px solid blue;  /* Adding blue border */
  padding: 10px;  /* Optional padding for better spacing */
  border-radius: 5px;  /* Optional rounded corners */
}

/* Optional: Add responsiveness (if needed) */
@media (max-width: 768px) {
  .side-border {
    border: 2px solid blue;
  }
}

.edit-button {
  display: flex;
  align-items: center; /* Vertically center the icon and text */
  background-color: #007bff; /* Blue background for the button */
  color: white; /* White text color */
  border-radius: 4px; /* Rounded corners for a modern look */
  padding: 8px 16px; /* Padding for better size */
  font-size: 14px; /* Adjust text size */
  border: none; /* Remove border */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

/* Change background on hover */
.edit-button:hover {
  background-color: #2a7acf; /* Darker blue when hovered */
}

/* In your CSS file */
.profile-pic {
  border-radius: 50%;
  transition: border 0.3s ease;
}

.profile-pic.highlighted {
  border: 2px solid blue;
}

@media screen and (min-width: 1024px) {
  .profile-main{
    top: -30px;
  }
}

.custom-photo-icon {
  width:50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  border-radius: 50%;
  object-fit: cover; /* Ensures image fills the circle */
}

@media only screen and (max-width: 767px) {
  .about-section{
    margin-top: 20px;
  }

  .about-section-main{
    margin-top: 10px;
    padding-top: 10px;
  }
}

@media (max-width: 768px) {
  .section {
      padding: 30px 0;
  }

  
}



.carousel-unique-width {
  width: 85%;
  text-align: center;
  justify-content: center;
}




@media (min-width: 1024px) {
  .spacing{
    margin-top: 5.5rem;
  }

  .profile_card {
    z-index: 1;
    top: 100px;
  }


  .about-section{
    margin-top: 20px;
  }

  .about-section-main{
    padding-top: 40px;
  }
}

/* Add to your custom CSS file */
.mentor-card {
  /* transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease; */
  border-color: #007bff;
}

.mentor-card:hover {
  transform: translateY(-8px); /* Lift the card slightly */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Add a stronger shadow */
  border-color: #007bff; /* Highlight border on hover */
}

