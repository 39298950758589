.studio-home {
    padding: 0px 20px 20px 20px;
}

/* Ensure consistent height and alignment for all cards */
.card-registration {
    height: 100%;
    /* Ensures cards stretch to the same height */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-body-registration {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* Center text vertically */
    align-items: center;
    text-align: center;
    padding: 1rem;
}

/* Uniform image styles */
.card-body-registration img {
    height: 60px;
    /* Set fixed height for all images */
    width: auto;
    /* Maintain aspect ratio */
    margin-bottom: 1rem;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
    .card-registration {
        margin: 0.5rem 0;
        /* Add spacing for mobile view */
    }

    .card-body-registration img {
        height: 50px;
        /* Adjust image size for smaller screens */
    }

    .login-account{
        font-size: 20px;
       }

    
    .desktop-icon{
        font-size: 12px;
    }

    .desktop-icon-size{
        margin-bottom: 3px;
        height: 30px;
    }

    .section-main{
        padding-top: 10px;
    }
    .user-main-row{
        padding-top: 20px;
    }
}

@media screen and (min-width: 1024px) {
   .index-section{
    margin-top: 70px;
    padding-top: 90px;
   }

   .main-row{
    margin-top: 40px;
   }

   .section-main{
    padding-top: 40px;
    top: -50px;
   }

   .login-account{
    font-size: 20px;
   }

   .desktop-icon{
        display: flex !important;
        font-size: 14px !important;
   }

   .desktop-icon-size{
    margin-bottom: 3px;
    height: 20px;
    margin-right: 6px;
}

#topnav .navigation-menu > li > a {
    padding-left: 10px;
    padding-right: 10px;
}

.user-main{
    margin-top: 40px;
    padding-top: 30px;
}

.user-main-row{
    padding-top: 40px;
}
}

.sec-center {
  position: relative;
}

.section-dropdown {
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

   /* .desktop-icon-size{
    height: 20px !important;
    width: 20px !important;
   } */
