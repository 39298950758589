.categories-tree {
    max-height: 400px;
    overflow-y: auto;
}

.category-item {
    border-radius: 4px;
    transition: all 0.3s ease;
}

.category-header {
    padding: 8px;
    background-color: #f8f9fa;
    border-radius: 4px;
}

.subcategory-item {
    padding: 4px 8px;
    margin: 2px 0;
    border-radius: 4px;
}

.subcategory-item:hover {
    background-color: #f8f9fa;
}

.expertise-section, .specializations-section {
    max-height: 200px;
    overflow-y: auto;
}

.category-item, .subcategory-item {
    border-radius: 4px;
    transition: all 0.2s ease;
}

.category-item:hover, .subcategory-item:hover {
    background-color: #f8f9fa;
}

.profile-section, .mentorship-type-section, .availability-section {
    border-radius: 8px;
    background-color: #f8f9fa;
    padding: 15px;
    margin-bottom: 20px;
}

.schedule-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
}

.schedule-item {
    padding: 8px;
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.bio-content {
    background-color: white;
    border-radius: 4px;
    padding: 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.bio-content .ql-editor {
    font-size: 16px;  /* Base font size */
}

.bio-content .ql-editor p {
    font-size: 16px;  /* For paragraphs */
    line-height: 1.6;  /* Better line spacing */
}

.bio-content .ql-editor h1,
.bio-content .ql-editor h2,
.bio-content .ql-editor h3 {
    font-size: 20px;  /* For headings if any */
}

.category-section {
    margin-bottom: 2rem;
}

.load-more-btn {
    height: 100%;
    min-height: 200px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    border: 2px dashed #dee2e6;
    transition: all 0.3s ease;
}

.load-more-btn:hover {
    background-color: #e9ecef;
    border-color: #adb5bd;
}

/* Hide scrollbar but keep functionality */
.overflow-x-auto {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.overflow-x-auto::-webkit-scrollbar {
    display: none;
}

/* Mentor Management Styles */
.status-pending {
  background-color: #ffd700;
  padding: 4px 8px;
  border-radius: 4px;
  color: #000;
}

.status-approved {
  background-color: #28a745;
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
}

.status-blocked {
  background-color: #dc3545;
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
}

.status-rejected {
  background-color: #6c757d;
  padding: 4px 8px;
  border-radius: 4px;
  color: #fff;
}
