.titlebar {
  background-color: #d6e5f5;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 15px !important;
}

.title_text {
  font-family: 'Assistant', sans-serif !important;
  font-size: 24px !important;
  background: blue !important;
  border: none !important;
}

.default-img {
  position: relative;
  bottom: 0;
  right: 0px;
  border-radius: 5px;
}

.card_body {
  background: #f4fbf5;
}

.registerdiv {
  box-shadow: 0px -1px 8px 1px #979797 !important;
}

.countryCodediv {
  position: absolute;
  top: 38px;
  left: 50px;
}

.countrycode {
  width: 52%;
  border: 3px solid #b1b1b1;
  line-height: 22px;
  font-size: 18px;
  padding: 6px 16px 6px 6px;
  border-radius: 0px;
}

.phoneInput {
  text-align: right;
  font-size: 18px;
}

#customCheck1 {
  float: none;
  margin-right: 10px;
  border: 2px solid;
  margin-top: 8px;
}

.form-check-input {
  margin-right: 10px;
  border: 2px solid;
  margin-top: 5px;
}

.label_text {
  font-size: 18px;
  font-family: 'Assistant', sans-serif;
  font-weight: 300;
}

.form_Input {
  font-size: 16px;
  font-family: 'Assistant', sans-serif;
  border-radius: 10px !important;
}

.register_text {
  font-size: 18px;
}

.PhoneInputInput,
.PhoneInputInput:focus {
  outline: none;
  border: none;
}

.flex-container {
  display: flex;
}

.fieldset {
  margin: 10px 10px 10px 1px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  border-width: 1px;
  border: 1px solid #adadad;
}

.legend {
  position: absolute;
  top: -14px;
  font-weight: bold;
  font-family: 'Assistant', sans-serif;
  background-color: #f4fbf5;
  padding: 0px 6px 0px 6px;
  font-size: 18px;
  left: 50%;
  transform: translate(-50%, 0);
}

.modalTitle {
  font-size: 18px;
  font-family: 'Assistant', sans-serif;
  font-weight: 600;
  align-items: center;
}

.close_Btn {
  left: 71% !important;
  position: fixed !important;
  background: none !important;
  font-size: 35px !important;
  padding: 0px 12px 0px 14px !important;
  color: #366989 !important;
  border: none !important;
  font-weight: 600 !important;
  top: 29px !important;
}

.special-label {
  display: none !important;
}

.flag-dropdown {
  height: 39px;
}

.react-tel-input {
  border: none !important;
  padding: 0px 0px 0px 0px !important;
  width: 85% !important;
}

.resend_div {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.resend_div:hover {
  color: blue;
}

.mainlogo {
  margin-top: 101px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.fontSize20 {
  font-size: 20px;
}

.fontSize14 {
  font-size: 14px;
}

.fontSize16 {
  font-size: 16px;
}
.fontSize22 {
  font-size: 22px;
}

.profilepic {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #111;
}

.profilepic .profilepic__content {
  opacity: 1;
}

/* .profilepic .profilepic__image {
} */
.no-image-opacity {
  opacity: 0.5 !important;
}
.profilepic__image {
  object-fit: cover;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

.profilepic__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  line-height: normal;
  cursor: pointer;
}

.profilepic__icon {
  color: white;
  /* padding-bottom: 8px; */
}

.fas {
  font-size: 20px;
}

.profilepic__text {
  text-transform: uppercase;
  font-size: 12px;
  width: 50%;
  text-align: center;
}

@keyframes slide-left {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.slide-left-animation {
  animation: 0.5s slide-left;
}

@keyframes slide-right {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }

  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}

.slide-right-animation {
  animation: 0.5s slide-right;
}
@media (min-width: 768px) {
  .bg-auth-home {
    padding-top: 15rem !important;
  }

  .registration-wel-sub{
    font-size: 14px;
  }
  

  .fontSize20 {
    font-size: 18px;
  }
  
}



@media screen and (min-width: 1024px) {
  .registration-main {
     padding-top: 50px;
  }

  .registration-section{
    margin-top: 50px;
  }

  .registration-card{
    margin-bottom: 20px;
  }

  .registration-wel{
    font-size: 22px;
  }

  .registration-wel-sub{
    font-size: 16px;
  }
  
}


.testimonial-card {
  width: 300px; /* Increased width */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: box-shadow 0.3s ease;
  background-color: transparent; /* Removed background color */
  
}

.testimonial-name {
  font-size: 1.5em;
  font-weight: bold;
  color: #666;
  margin-top: 15px;
}

.testimonial-card  {
  font-size: 0.9em;
  color: #666;
  margin-top: 10px;
  text-align: center;
}

.designation {
  font-size: 0.9rem;
  margin-top: 5px;
  text-align: center;
  color: #666;
}

.social-linkedin{
  margin-left: 2px;
}

.social-icons a {
  color: #555;
  font-size: 1.2em;
  margin-right: 8px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #007bff;
}

/* Add to your custom CSS file */
.mentor-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
}

.mentor-card:hover {
  transform: translateY(-8px); /* Lift the card slightly */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Add a stronger shadow */
  border-color: #007bff; /* Highlight border on hover */
}
@media (min-width: 768px) {
.for_home_cards{
  width: 19.666667% !important;
}
}
@media (max-width: 768px) {
.for_home_cards{
  width: 100% !important;
}
}

@media (max-width: 767px) {
  .leadership-main {
    margin-top: 10px;  /* smaller margin on mobile screens */
  }
}

@media (min-width: 768px) {
  .leadership-main {
    margin-top: 95px;  /* larger margin on desktop screens */
  }
}
