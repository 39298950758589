.as-members-home-page {
  padding-top: 100px; /* Adjust this value based on the height of your top bar */
}

.top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
}

.content {
  margin-top: 20px;
}

.search-panel-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 8px 24px rgba(140,152,164,.2);
}

@media (max-width: 768px) {
  .search-panel-wrapper {
    margin: 0 -15px;
    border-radius: 0;
  }

  .section {
    padding-top: 1rem;
  }

  h1.display-4 {
    font-size: 2rem;
  }

  .lead {
    font-size: 1rem;
  }
}

/* Animate gradient background */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.display-4 {
  animation: gradientAnimation 6s ease infinite;
  background-size: 200% auto;
}

/* Enhance mobile scrolling experience */
.category-wrapper {
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.category-wrapper::-webkit-scrollbar {
  display: none;
}

/* Add smooth transitions */
.member-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.member-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}
